<!-- 故障码管理 -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">故障码管理</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 faultCodeAnalysisBody">
            <div class="flex pdt_20 fn_size16">
                <el-select v-model="value" placeholder="分厂选择" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-select v-model="value" placeholder="工段选择" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-select v-model="value" placeholder="设备类型" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-select v-model="value" placeholder="设备选择" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-select v-model="value" placeholder="设备名称" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-button type="primary" class="alarmQuerySelectButton flex justify_center align_center">查询</el-button>
            </div>
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_120 bgc_03CABE buttonBorder" style="background-color: #03CABE;">新加</el-button>
                    <el-button type="primary" icon="el-icon-edit" class="width_120 bgc_FEA313 buttonBorder" style="background-color: #FEA313;">编辑
                    </el-button>
                    <el-button type="primary" icon="el-icon-delete"
                        class="width_120 mg_left_10 bgc_F75753 buttonBorder" style="background-color: #F75753;">删除</el-button>
                </div>
                <div>
                    <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导入</el-button>
                <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出</el-button>
                </div>
            </div>
            <div>
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
                    class="mg_top_20" @selection-change="handleSelectionChange" max-height="500"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="公司" width=""   show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="分厂/车间" width=""  ></el-table-column>
                    <el-table-column prop="date" label="工段/产线" width=""  ></el-table-column>
                    <el-table-column prop="name" label="资产编号" width=""  ></el-table-column>
                    <el-table-column prop="address" label="设备名称" width="" show-overflow-tooltip  ></el-table-column>
                    <el-table-column prop="address" label="故障代码" show-overflow-tooltip  ></el-table-column>
                    <el-table-column prop="name" label="故障描述"  ></el-table-column>
                    <el-table-column prop="name" label="提交人"  ></el-table-column>
                    <el-table-column prop="date" label="更新时间"  ></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;">More</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'

    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                options: [],
                tableData: [{
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                multipleSelection: []
            }
        },
        mounted() {},
        methods: {
            handleSelectionChange(val) {
                // console.log(val)
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .faultCodeAnalysisBody{
        height: 710px;
    }

</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>